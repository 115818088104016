.horizontal-gallery-container {
  overflow-x: scroll;
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.horizontal-gallery-row {
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.horizontal-gallery-image {
  height: 30vh; /* Adjust to your preference */
  width: auto;
  margin: 2px;
  cursor: pointer;
  transition: transform 0.3s;
}

.horizontal-gallery-image:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .horizontal-gallery-image {
      height: 30vh;
  }
}