.hamburger-menu {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1001;
    padding: 1rem;
    align-items: right;
    top: 0;
    right: 0;
}

button {
    font-size: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.links {
    position: fixed; /* Make it cover the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* 50% opacity black background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}

.links a {
    display: block;
    font-size: 2em; /* Large font size */
    padding: 20px;
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
}

.links a:hover {
    /* background-image: radial-gradient(rgba(255, 255, 255, 0.1) 80%, transparent); */
    background-color: rgba(255, 255, 255, 0.1);
    color: white; /* Light grey on hover */
    border-radius: 0.5rem;
}

.close-btn {
    position: absolute; /* Positioning the button on top right */
    top: 10%;
    right: 10%;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 1001; /* Ensuring it appears on top */
}

.close-btn:hover {
    color: gray; /* Change color on hover. You can use any color you like */
}
