.about-container {
    min-height: 100vh;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
}

.about-text p {
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.3rem;
}

/* mobile */
@media (max-width: 766px) {
    .about-text p {
        font-size: 1.2rem;
    }
}
.about-img-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
}

.about-img-container-mobile {
    margin-top: 10vh;
}
