.hero-container {
    position: relative;
    overflow: hidden;
}

.hero-container::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-Fill.jpg") no-repeat center center/cover;
}

/* mobile */
@media (max-width: 766px) {
    .hero-container::before {
        background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-stretch-tall.jpg") no-repeat center center/cover;
    }

    .hero-quote-text {
        font-size: 1.4rem;
        letter-spacing: -0.035em;
        line-height: 2.5rem;
    }

    .hero-quote-author {
        font-size: 2rem;
        line-height: 1.6rem;
        letter-spacing: 0.035em;
    }
}

@media (min-width: 766px) and (max-width: 1025px){
    .hero-container::before {
        background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-iPad.png") no-repeat center center/cover;
    }
    .hero-quote-text {
        font-size: 1.8rem;
        letter-spacing: -0.035em;
        line-height: 2.8rem;
    }

    .hero-quote-author {
        font-size: 2.5rem;
        line-height: 2rem;
        letter-spacing: 0.035em;
    }
}

/* desktop */
@media (min-width: 1024px) {
    .gradient {
        content: "";
        width: 65%;
        height: 100%;
        left: 0px;
        top: 0px;
        position: fixed;
        z-index: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 14.62%, rgba(16, 15, 13, 0.675) 63.35%, rgba(16, 15, 13, 0) 101.11%);
    }

    .hero-quote-text {
        font-size: 2rem;
        letter-spacing: -0.035em;
        line-height: 3.125rem;
    }

    .hero-quote-author {
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.035em;
    }
}

.hero-quote-text,
.hero-quote-author {
    font-style: normal;
    font-weight: 400;
}

.hero-quote {
    color: white;
    font-family: 'Modern M';
}

.logo-container-hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1000; */
}