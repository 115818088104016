.recording {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 100vh; /* Set to full viewport height */
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    backdrop-filter: blur(5px); /* Optional: Adds a blur effect to the background */
    padding: 20px;
    overflow: auto; /* Allow scrolling if content overflows */
  }
  
  .recording-container {
    width: 90vw;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .recording-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 70vh;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }