.event-card {
    background: #D9D9D9;
    border-radius: 0.9rem;
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 900px;
    cursor: pointer;
}

.event-card h4 {
    margin-bottom: 10px;
}

.event-card a {
    display: block;
}

.event-name {
    font-family: 'Sunshine';
    font-size: 1.5rem;
}

.location {
    font-family: 'Evagrande'
}

.program {
    font-family: 'Qlassy'
}

.ticket-text a {
    text-decoration: none;
    font-family: 'Sunshine';
    color: #0f0e0e;
    font-size: 0.6rem;
}

.ticket-text a:hover {
    text-decoration: underline;
    -webkit-tap-highlight-color: white;
}

.rsvp-link {
    font-family: 'Sunshine';
    font-size: 1rem;
    color: #0f0e0e;
}

@media (max-width: 766px) {
    .event-card {
        padding: 0.5rem;
        margin-bottom: 0.8rem;
    }

    .event-name {
        font-size: 1rem;
    }

    .location {
        font-size: 0.8rem;
    }

    .program {
        font-size: 0.9rem;
    }
}

.modal-footer {
    background-color: #D9D9D9
}

.modal-body {
    background-color: #D9D9D9
}

.modal-header {
    background-color: #D9D9D9
}

p {
    color: #0f0e0e
}

.modal-content {
    border-radius: 1.2rem !important;
}