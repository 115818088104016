.schedule {
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.schedule-header h1 {
    font-family: 'Modern M';
    color: white;
    text-align: center;
    letter-spacing: 0.25rem;
}

.schedule-body {
    margin-top: 4rem;
    margin-bottom: 6rem;
}

.janice-logo {
    text-align: center;
}

.custom-dropdown-toggle {
    background-color: transparent !important; /* Make background transparent */
    font-family: 'Sunshine' !important; /* Update font family */
    letter-spacing: 0.25rem !important;
    font-size: 16px; /* Update font size */
    border: none !important; /* Remove border */
}

.custom-dropdown-menu {
    background-color: transparent !important; /* Make background transparent */
    font-family: Arial, sans-serif; /* Update font family */
    font-size: 14px; /* Update font size */
    border: none !important;
}

.custom-dropdown-item {
    color: white !important; /* Make text color white */
    font-family: 'Sunshine' !important; /* Update font family */
    letter-spacing: 0.25rem !important;
}

.custom-dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.25) !important; /* White background with 25% opacity */
    border-color: rgba(255, 255, 255, 0.1) !important; /* Faded border color */
}

.keyboard {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: left;
}