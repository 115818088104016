body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0e0e;
}

html {
  background-color: #0f0e0e;
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Modern M';
  src: url("/static/media/modern M.0f5f68c9.ttf") format('truetype');
}

@font-face {
  font-family: 'Sunshine';
  src: url(/static/media/Sunshine.ce41f13f.ttf) format('truetype');
}

@font-face {
  font-family: 'Qlassy';
  src: url(/static/media/Qlassy.5802665e.ttf) format('truetype');
}

@font-face {
  font-family: 'Le Jour Serif';
  src: url(/static/media/LeJourSerif.0fc59c9e.ttf) format('truetype');
}

@font-face {
  font-family: 'Evagrande';
  src: url(/static/media/Evagrande.8d46f686.ttf) format('truetype');
}

@font-face {
  font-family: 'Sedan SC';
  src: url(/static/media/SedanSC-Regular.7806c609.ttf) format('truetype');
}

@font-face {
  font-family: 'Mouser';
  src: url("/static/media/Mouser D.4da9be06.otf") format('truetype');
}

.App {
  height: 100vh;
}
.hero-container {
    position: relative;
    overflow: hidden;
}

.hero-container::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-transform: none;
            transform: none;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-Fill.jpg") no-repeat center center/cover;
}

/* mobile */
@media (max-width: 766px) {
    .hero-container::before {
        background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-stretch-tall.jpg") no-repeat center center/cover;
    }

    .hero-quote-text {
        font-size: 1.4rem;
        letter-spacing: -0.035em;
        line-height: 2.5rem;
    }

    .hero-quote-author {
        font-size: 2rem;
        line-height: 1.6rem;
        letter-spacing: 0.035em;
    }
}

@media (min-width: 766px) and (max-width: 1025px){
    .hero-container::before {
        background: url("https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5184-Edit-iPad.png") no-repeat center center/cover;
    }
    .hero-quote-text {
        font-size: 1.8rem;
        letter-spacing: -0.035em;
        line-height: 2.8rem;
    }

    .hero-quote-author {
        font-size: 2.5rem;
        line-height: 2rem;
        letter-spacing: 0.035em;
    }
}

/* desktop */
@media (min-width: 1024px) {
    .gradient {
        content: "";
        width: 65%;
        height: 100%;
        left: 0px;
        top: 0px;
        position: fixed;
        z-index: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 14.62%, rgba(16, 15, 13, 0.675) 63.35%, rgba(16, 15, 13, 0) 101.11%);
    }

    .hero-quote-text {
        font-size: 2rem;
        letter-spacing: -0.035em;
        line-height: 3.125rem;
    }

    .hero-quote-author {
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.035em;
    }
}

.hero-quote-text,
.hero-quote-author {
    font-style: normal;
    font-weight: 400;
}

.hero-quote {
    color: white;
    font-family: 'Modern M';
}

.logo-container-hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 1000; */
}
.hamburger-menu {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1001;
    padding: 1rem;
    align-items: right;
    top: 0;
    right: 0;
}

button {
    font-size: 3rem;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
}

.links {
    position: fixed; /* Make it cover the entire screen */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* 50% opacity black background */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;

}

.links a {
    display: block;
    font-size: 2em; /* Large font size */
    padding: 20px;
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
}

.links a:hover {
    /* background-image: radial-gradient(rgba(255, 255, 255, 0.1) 80%, transparent); */
    background-color: rgba(255, 255, 255, 0.1);
    color: white; /* Light grey on hover */
    border-radius: 0.5rem;
}

.close-btn {
    position: absolute; /* Positioning the button on top right */
    top: 10%;
    right: 10%;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 1001; /* Ensuring it appears on top */
}

.close-btn:hover {
    color: gray; /* Change color on hover. You can use any color you like */
}

.logo-container-mobile {
    top: 0;
    left: 0;
    position: fixed;
}

.logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.horizontal-gallery-container {
  overflow-x: scroll;
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.horizontal-gallery-row {
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.horizontal-gallery-image {
  height: 30vh; /* Adjust to your preference */
  width: auto;
  margin: 2px;
  cursor: pointer;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.horizontal-gallery-image:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (max-width: 600px) {
  .horizontal-gallery-image {
      height: 30vh;
  }
}
.photos {
    min-height: 100vh;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}

.image-gallery-container {
    padding-top: 10rem;
}

@media (max-width: 766px) {
    .image-gallery-container {
        padding-top: 5rem;
    }    
}


.about-container {
    min-height: 100vh;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
}

.about-text p {
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.3rem;
}

/* mobile */
@media (max-width: 766px) {
    .about-text p {
        font-size: 1.2rem;
    }
}
.about-img-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100vh;
}

.about-img-container-mobile {
    margin-top: 10vh;
}

.schedule {
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.schedule-header h1 {
    font-family: 'Modern M';
    color: white;
    text-align: center;
    letter-spacing: 0.25rem;
}

.schedule-body {
    margin-top: 4rem;
    margin-bottom: 6rem;
}

.janice-logo {
    text-align: center;
}

.custom-dropdown-toggle {
    background-color: transparent !important; /* Make background transparent */
    font-family: 'Sunshine' !important; /* Update font family */
    letter-spacing: 0.25rem !important;
    font-size: 16px; /* Update font size */
    border: none !important; /* Remove border */
}

.custom-dropdown-menu {
    background-color: transparent !important; /* Make background transparent */
    font-family: Arial, sans-serif; /* Update font family */
    font-size: 14px; /* Update font size */
    border: none !important;
}

.custom-dropdown-item {
    color: white !important; /* Make text color white */
    font-family: 'Sunshine' !important; /* Update font family */
    letter-spacing: 0.25rem !important;
}

.custom-dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.25) !important; /* White background with 25% opacity */
    border-color: rgba(255, 255, 255, 0.1) !important; /* Faded border color */
}

.keyboard {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: left;
}
.date {
    width: 100%;
    height: 100%;
    position: relative;
}
.event-card {
    background: #D9D9D9;
    border-radius: 0.9rem;
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 900px;
    cursor: pointer;
}

.event-card h4 {
    margin-bottom: 10px;
}

.event-card a {
    display: block;
}

.event-name {
    font-family: 'Sunshine';
    font-size: 1.5rem;
}

.location {
    font-family: 'Evagrande'
}

.program {
    font-family: 'Qlassy'
}

.ticket-text a {
    text-decoration: none;
    font-family: 'Sunshine';
    color: #0f0e0e;
    font-size: 0.6rem;
}

.ticket-text a:hover {
    text-decoration: underline;
    -webkit-tap-highlight-color: white;
}

.rsvp-link {
    font-family: 'Sunshine';
    font-size: 1rem;
    color: #0f0e0e;
}

@media (max-width: 766px) {
    .event-card {
        padding: 0.5rem;
        margin-bottom: 0.8rem;
    }

    .event-name {
        font-size: 1rem;
    }

    .location {
        font-size: 0.8rem;
    }

    .program {
        font-size: 0.9rem;
    }
}

.modal-footer {
    background-color: #D9D9D9
}

.modal-body {
    background-color: #D9D9D9
}

.modal-header {
    background-color: #D9D9D9
}

p {
    color: #0f0e0e
}

.modal-content {
    border-radius: 1.2rem !important;
}
.event-modal-card {
    background: #D9D9D9;
    border-radius: 1.2rem;
    position: relative;
    padding: 20px;
    cursor: pointer;
}

.event-modal-card h4 {
    margin-bottom: 10px;
}

.event-modal-card a {
    display: block;
}

.event-modal-name {
    font-family: 'Sunshine';
    font-size: 2rem;
}

.event-modal-line {
    height: 100%;
    border: 2px solid #0f0e0e;
    content: "";
    left: 18%;
    top: 0px;
    position: absolute;
    overflow: hidden;
}

.event-modal-location {
    font-family: 'Evagrande';
    font-size: 1.5rem;
}

.event-modal-date {
    margin-left: 1.2rem;
    margin-top: 5%;
}

.program {
    font-family: 'Qlassy'
}

.program-long {
    font-family: 'Sedan SC';
    font-size: 1.2rem;
}

.participants {
    font-family: 'Sedan SC'
}

.ticket-text a {
    text-decoration: none;
    font-family: 'Sunshine';
    color: #0f0e0e;
    font-size: 0.6rem;
}

.ticket-text a:hover {
    text-decoration: underline;
    -webkit-tap-highlight-color: white;
}

.event-modal-date-mobile {
    font-family: 'Sedan SC';
}

@media (max-width: 766px) {
    .event-modal-card {
        padding: 0.5rem;
    }

    .event-modal-name {
        font-size: 1rem;
    }

    .event-modal-location {
        font-size: 0.8rem;
    }

    .event-modal-date {
        margin-left: 0.8rem;
        margin-top: 5%;
    }

    .program {
        font-size: 0.8rem;
    }

    .program-long {
        font-size: 0.8rem;
    }

    .participants {
        font-size: 0.8rem;
    }

    .ticket-text a {
        font-size: 0.6rem;
    }
}
.contact-page {
    width: 100%;
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    min-height: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
  
.management-photo {
    height: 180px;
    margin-bottom: 5px;
    object-fit: cover;
}

.contact-info h2{
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.5rem;
}

.contact-info p{
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.2rem;
    margin-top: 5px;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.img-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 80vh;
}

.img-container-mobile {
    margin-top: 5vh;
}

@media (max-width: 768px) {
    .contact-info-container {
        margin-top: 50px;
    }
}
.recording {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    height: 100vh; /* Set to full viewport height */
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px); /* Optional: Adds a blur effect to the background */
    padding: 20px;
    overflow: auto; /* Allow scrolling if content overflows */
  }
  
  .recording-container {
    width: 90vw;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .recording-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 70vh;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
