@font-face {
  font-family: 'Modern M';
  src: url('./fonts/modern\ M.ttf') format('truetype');
}

@font-face {
  font-family: 'Sunshine';
  src: url('./fonts/Sunshine.ttf') format('truetype');
}

@font-face {
  font-family: 'Qlassy';
  src: url('./fonts/Qlassy.ttf') format('truetype');
}

@font-face {
  font-family: 'Le Jour Serif';
  src: url('./fonts/LeJourSerif.ttf') format('truetype');
}

@font-face {
  font-family: 'Evagrande';
  src: url('./fonts/Evagrande.ttf') format('truetype');
}

@font-face {
  font-family: 'Sedan SC';
  src: url('./fonts/SedanSC-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mouser';
  src: url('./fonts/Mouser\ D.otf') format('truetype');
}

.App {
  height: 100vh;
}