.event-modal-card {
    background: #D9D9D9;
    border-radius: 1.2rem;
    position: relative;
    padding: 20px;
    cursor: pointer;
}

.event-modal-card h4 {
    margin-bottom: 10px;
}

.event-modal-card a {
    display: block;
}

.event-modal-name {
    font-family: 'Sunshine';
    font-size: 2rem;
}

.event-modal-line {
    height: 100%;
    border: 2px solid #0f0e0e;
    content: "";
    left: 18%;
    top: 0px;
    position: absolute;
    overflow: hidden;
}

.event-modal-location {
    font-family: 'Evagrande';
    font-size: 1.5rem;
}

.event-modal-date {
    margin-left: 1.2rem;
    margin-top: 5%;
}

.program {
    font-family: 'Qlassy'
}

.program-long {
    font-family: 'Sedan SC';
    font-size: 1.2rem;
}

.participants {
    font-family: 'Sedan SC'
}

.ticket-text a {
    text-decoration: none;
    font-family: 'Sunshine';
    color: #0f0e0e;
    font-size: 0.6rem;
}

.ticket-text a:hover {
    text-decoration: underline;
    -webkit-tap-highlight-color: white;
}

.event-modal-date-mobile {
    font-family: 'Sedan SC';
}

@media (max-width: 766px) {
    .event-modal-card {
        padding: 0.5rem;
    }

    .event-modal-name {
        font-size: 1rem;
    }

    .event-modal-location {
        font-size: 0.8rem;
    }

    .event-modal-date {
        margin-left: 0.8rem;
        margin-top: 5%;
    }

    .program {
        font-size: 0.8rem;
    }

    .program-long {
        font-size: 0.8rem;
    }

    .participants {
        font-size: 0.8rem;
    }

    .ticket-text a {
        font-size: 0.6rem;
    }
}