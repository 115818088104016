.photos {
    min-height: 100vh;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}

.image-gallery-container {
    padding-top: 10rem;
}

@media (max-width: 766px) {
    .image-gallery-container {
        padding-top: 5rem;
    }    
}

