.logo-container-mobile {
    top: 0;
    left: 0;
    position: fixed;
}

.logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}