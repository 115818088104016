.contact-page {
    width: 100%;
    background-color: #0f0e0e;
    background: #0f0e0e;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    min-height: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
  
.management-photo {
    height: 180px;
    margin-bottom: 5px;
    object-fit: cover;
}

.contact-info h2{
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.5rem;
}

.contact-info p{
    color: #D9D9D9;
    font-family: 'Modern M';
    font-size: 1.2rem;
    margin-top: 5px;
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.img-container {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 80vh;
}

.img-container-mobile {
    margin-top: 5vh;
}

@media (max-width: 768px) {
    .contact-info-container {
        margin-top: 50px;
    }
}